import React from 'react'
import { TwitterTimelineEmbed, TwitterFollowButton } from 'react-twitter-embed'
import { styles } from '../../Theme'
import { ContainerSocialNetwork } from '.'
import styled from 'styled-components'

const ContainerTwitter = () => {
  return (
    <ContainerSocialNetwork
      title='Twitter'
      color={styles.colors.blueTwitter}
      header={
        <TwitterFollowButtonContainer>
          <TwitterFollowButton screenName={'Arroz_Sul'} lang='pt-br' options={{ size: 'large' }} />
        </TwitterFollowButtonContainer>
      }
      section={
        <TwitterTimelineEmbed sourceType='profile' screenName='Arroz_Sul' options={{ height: 459 }} noFooter noHeader />
      }
    />
  )
}

const TwitterFollowButtonContainer = styled.div`
  width: 300px;
  overflow: hidden;
  @media (min-width: 360px) {
    width: auto;
  }
`

export default ContainerTwitter
