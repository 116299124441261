import React, { useRef } from 'react'
import { FacebookProvider, Page, Like } from 'react-facebook'
import { TitleCard } from '../../components'
import styled from 'styled-components'
import { Flex, Box } from 'reflexbox'
import { styles } from '../../Theme'

const ContainerSocialNetwork = ({ title, color, section, header }) => {
  return (
    <Wrapper flexWrap='wrap' color={color}>
      <Header color={color}>
        <StyledTitle color={styles.colors.white}>{title}</StyledTitle>
        {header}
      </Header>
      <Section>{section}</Section>
    </Wrapper>
  )
}

export default ContainerSocialNetwork

const Wrapper = styled.div`
  max-width: 500px;
  height: 533px;
  border: 1px solid ${({ color }) => color};
  border-radius: 4px;
  width: 300px;
  @media (min-width: 360px) {
    width: 350px;
  }
  @media (min-width: 480px) {
    width: 400px;
  }
  @media (min-width: 768px) {
    width: 500px;
  }
  @media (min-width: 832px) {
    width: 400px;
  }
  @media (min-width: 992px) {
    width: 400px;
  }
  @media (min-width: 1054px) {
    width: 500px;
  }
`
const Header = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  padding: 8px;
  background: ${({ color }) => color};
  @media (min-width: 480px) {
    padding: 16px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`
const Section = styled(Box)`
  width: 100%;
`
const StyledTitle = styled(TitleCard)`
  font-size: 20px;
  @media (min-width: 768px) {
    font-size: 24px;
  }
`
