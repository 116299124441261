import styled from 'styled-components'

const Logo = styled.img`
  width: 129px;
  height: 48px;
  @media screen and (min-width: 480px) {
    width: 169px;
    height: 66px;
  }
`

export default Logo
