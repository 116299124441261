import React from 'react'
import { TitleCard, Text } from '../Typography'
import { styles } from '../../Theme/styles'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Table = ({ title, source, header, body, error }) => {
  const { t } = useTranslation()
  return (
    <Card>
      <CardHeader>
        <TitleCard color={styles.colors.red}>{title}</TitleCard>
        <FontTitle color={styles.colors.lightGrey}>{source}</FontTitle>
      </CardHeader>
      <CardBody>
        <TableContent>
          <Head key={header.id}>
            <Item>
              {header &&
                header.map((thead, index) => (
                  <HeaderItem key={thead.title} first={index === 0}>
                    {thead.title}
                  </HeaderItem>
                ))}
            </Item>
          </Head>
          <Body>
            {(_ => {
              if (error) {
                return (
                  <tr>
                    <Error as='td'>{t('table:error')}</Error>
                  </tr>
                )
              }
              if (body.length) {
                return body.map(item => (
                  <Item key={item.name}>
                    <Data first>{item.name}</Data>
                    <Data>{item.price}</Data>
                    <Data>
                      {item.variation > 0 ? <Success>+{item.variation}%</Success> : <Danger>{item.variation}%</Danger>}
                    </Data>
                  </Item>
                ))
              } else {
                return (
                  <tr>
                    <Error as='td'>{t('table:no_data')}</Error>
                  </tr>
                )
              }
            })()}
          </Body>
        </TableContent>
      </CardBody>
    </Card>
  )
}

const Card = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  margin: 48px 12px 32px 12px;
`

const CardHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 0 14px 0;
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`
const CardBody = styled.div`
  display: flex;
  justify-content: center;
  min-height: 300px;
  background: #ffffff;
  -webkit-box-shadow: 0px 2px 8px rgba(171, 169, 188, 0.2);
  -moz-box-shadow: 0px 2px 8px rgba(171, 169, 188, 0.2);
  box-shadow: 0px 2px 8px rgba(171, 169, 188, 0.2);
`

const TableContent = styled.table`
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 4px;
`
const Head = styled.thead`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  box-shadow: inset 0px -1px 0px rgba(70, 69, 85, 0.3);
  tr {
    box-shadow: none;
  }
`
const HeaderItem = styled.th`
  font-family: 'Montserrat-Bold';
  font-size: 12px;
  @media (min-width: 360px) {
    font-size: 14px;
  }
  width: 33%;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.darkGrey};
  text-align: ${({ first }) => (first ? 'left' : 'right')};
  line-height: 20px;
  padding: 14px 2px;
`

const Body = styled.tbody`
  flex: 1;
  padding: 11px 0;
  min-height: 185px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const Item = styled.tr`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px rgba(70, 69, 85, 0.3);
  &:last-of-type {
    box-shadow: none;
  }
`
const Data = styled.td`
  font-size: 12px;
  @media (min-width: 360px) {
    font-size: 14px;
  }
  width: 33%;
  display: flex;
  justify-content: center;
  text-align: left;
  font-weight: 500;
  line-height: 26px;
  padding: 10px 0;
  color: ${({ theme }) => theme.colors.darkGrey};
  text-align: ${({ first }) => (first ? 'left' : 'right')};
`
const Success = styled.span`
  color: ${({ theme }) => theme.colors.green};
`
const Danger = styled.span`
  color: ${({ theme }) => theme.colors.red};
`
const FontTitle = styled(Text)`
  display: flex;
  align-items: center;
  margin-left: 0;
  @media screen and (min-width: 1200px) {
    margin-left: 24px;
  }
`
const Error = styled.h1`
  text-align: center;
  color: ${({ theme }) => theme.colors.darkGrey};
`

export default Table
