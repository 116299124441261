import styled from 'styled-components'
import PropTypes from 'prop-types'

const DefaultTypography = styled.p`
  ${props => {
    return `
      font-family: ${props.fontFamily || 'Montserrat'};
      color: ${props.color || props.theme.colors.darkGrey};
      ${props.fontSize ? `font-size: ${props.fontSize};` : ''}
      ${props.uppercase ? 'text-transform: uppercase;' : ''}
      ${props.lineHeight ? `line-height: ${props.lineHeight};` : ''}}
    `
  }};
`

DefaultTypography.propTypes = {
  as: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  fontFamily: PropTypes.string
}

export default DefaultTypography
