import React, { useState } from 'react'
import styled from 'styled-components'
import logoImg from '../../images/logo.svg'
import Hamburger from './Hamburger'
import NavLink from 'gatsby-link'
import { useTranslation } from 'react-i18next'
import i18n from '../../translations'
import translatePt from '../../images/brazil.svg'
import translateEn from '../../images/united-states.svg'
import translateEs from '../../images/spain.svg'

const Header = () => {
  const [whichLinkIsActive, setWhichLinkIsActive] = useState(null)
  const [isMenuMobileOpen, setMenuMobileOpen] = useState(false)
  const [isSubMenu, setSubMenu] = useState(false)
  const { t } = useTranslation()

  const changeLanguage = lng => {
    i18n.changeLanguage(lng)
  }

  const handleGetProps = ({ isPartiallyCurrent, location: { pathname } }) =>
    isPartiallyCurrent && setWhichLinkIsActive(pathname.split('/')[1])

  const handleMenuMobile = () => setMenuMobileOpen(!isMenuMobileOpen)

  const handleSelectedOption = link => typeof window !== 'undefined' && window.location.pathname.includes(link)

  return (
    <NavContainer isMenuMobileOpen={isMenuMobileOpen}>
      <CustomContainer>
        <StyledLink to='/'>
          <Logo src={logoImg} alt='Arroz Sul' />
        </StyledLink>
        <Hamburger handleMenuMobile={handleMenuMobile} isMenuMobileOpen={isMenuMobileOpen} />
      </CustomContainer>
      <ListItems isMenuMobileOpen={isMenuMobileOpen}>
        <Item isMenuMobileOpen={isMenuMobileOpen}>
          <StyledLink to='/' activeClassName='active'>
            {t('header:home')}
          </StyledLink>
        </Item>
        <Item isMenuMobileOpen={isMenuMobileOpen}>
          <StyledLink to='/about' activeClassName='active'>
            {t('header:about')}
          </StyledLink>
        </Item>
        <Item isMenuMobileOpen={isMenuMobileOpen}>
          <MenuDropdown>
            <Span className={whichLinkIsActive === 'services' ? 'active' : ''} onClick={() => setSubMenu(!isSubMenu)}>
              {t('header:services')}
            </Span>
            <SubMenu className={isMenuMobileOpen ? (isSubMenu ? 'open' : '') : ''}>
              <SubLink
                getProps={handleGetProps}
                to='/services/real-estate'
                selected={handleSelectedOption('/services/real-estate')}
              >
                {t('header:realEstateAdvice')}
              </SubLink>
              <SubLink
                getProps={handleGetProps}
                to='/services/commodities'
                selected={handleSelectedOption('/services/commodities')}
              >
                {t('header:commodityExchange')}
              </SubLink>
              <SubLink
                getProps={handleGetProps}
                to='/services/rice-trading'
                selected={handleSelectedOption('/services/rice-trading')}
              >
                {t('header:riceTrading')}
              </SubLink>
              <SubLink
                getProps={handleGetProps}
                to='/services/foreign-trade'
                selected={handleSelectedOption('/services/foreign-trade')}
              >
                {t('header:foreignTrade')}
              </SubLink>
              <SubLink
                getProps={handleGetProps}
                to='/services/fertilizers'
                selected={handleSelectedOption('/services/fertilizers')}
              >
                {t('header:fertilizers')}
              </SubLink>
              <SubLink
                getProps={handleGetProps}
                to='/services/drying-and-storing'
                selected={handleSelectedOption('/services/drying-and-storing')}
              >
                {t('header:dryingStorage')}
              </SubLink>
            </SubMenu>
          </MenuDropdown>
        </Item>
        <Item isMenuMobileOpen={isMenuMobileOpen}>
          <StyledLink to='/contact' activeClassName='active'>
            {t('header:contact')}
          </StyledLink>
        </Item>
        <TranslateContentMobile isMenuMobileOpen={isMenuMobileOpen}>
          <Img src={translatePt} onClick={() => changeLanguage('pt')} alt='Traduzir para português' />
          <Img src={translateEs} onClick={() => changeLanguage('es')} alt='Traducir al español' />
          <Img src={translateEn} onClick={() => changeLanguage('en')} alt='Translate to english' />
        </TranslateContentMobile>
      </ListItems>
      <TranslateContent>
        <Img src={translatePt} onClick={() => changeLanguage('pt')} alt='Traduzir para português' />
        <Img src={translateEs} onClick={() => changeLanguage('es')} alt='Traducir al español' />
        <Img src={translateEn} onClick={() => changeLanguage('en')} alt='Translate to english' />
      </TranslateContent>
    </NavContainer>
  )
}

const NavContainer = styled.nav`
  position: fixed;
  width: 100%;
  max-height: 100vh;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  ${({ isMenuMobileOpen }) =>
    isMenuMobileOpen ? 'box-shadow: none' : 'box-shadow: 0px 8px 8px rgba(70, 69, 85, 0.3);'};
  @media (min-width: 1200px) {
    box-shadow: 0px 8px 8px rgba(70, 69, 85, 0.3);
    flex-direction: row;
  }
`
const CustomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 64px;
  background-color: #fff;
  @media screen and (min-width: 1200px) {
    width: auto;
    height: 98px;
  }
`
const StyledLink = styled(NavLink)`
  list-style-type: none;
  text-decoration: none;
  font-family: Montserrat;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 20px;
  &:hover {
    color: ${({ theme }) => theme.colors.red};
  }
  @media screen and (min-width: 1200px) {
    font-size: 16px;
  }
`
const SubLink = styled(NavLink)`
  list-style-type: none;
  text-decoration: none;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
  color: ${({ theme, selected }) => (selected ? theme.colors.red : theme.colors.darkGrey)};
  text-align: center;
  margin-top: 15px;
  transition: color 0.3s ease-in-out;
  :hover {
    color: ${({ theme }) => theme.colors.lightGrey};
  }
  @media screen and (min-width: 1200px) {
    text-align: left;
    color: ${({ theme }) => theme.colors.white};
    margin-top: 0;
  }
`
const Span = styled.span`
  list-style-type: none;
  text-decoration: none;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.darkGrey};
  cursor: pointer;
  @media screen and (min-width: 1200px) {
    font-size: 16px;
    padding-bottom: 40px;
  }
`
const ListItems = styled.ul`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${({ isMenuMobileOpen }) => (isMenuMobileOpen ? '100vh' : '0')};
  ${({ isMenuMobileOpen }) => (isMenuMobileOpen ? 'overflow: auto' : '')};
  transition: all 0.5s ease-in-out;
  @media screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    height: 100%;
    width: 500px;
  }
`
const Item = styled.li`
  display: ${({ isMenuMobileOpen }) => (isMenuMobileOpen ? 'flex' : 'none')};
  transition: all 0.1s ease-in-out;
  width: 100%;
  margin-top: 55px;
  text-align: center;
  align-items: center;
  flex-direction: column;
  &:hover {
    color: ${({ theme }) => theme.colors.red};
  }
  .active {
    color: ${({ theme }) => theme.colors.red};
    font-family: Montserrat-bold;
  }
  @media screen and (min-width: 1200px) {
    display: flex;
    margin: 0 auto;
    font-weight: 800;
  }
`
const Logo = styled.img`
  padding: 7px 0px 9px 16px;
  width: 129px;
  @media screen and (min-width: 1200px) {
    padding: 16px 0px 16px 40px;
    width: 169px;
  }
`
const Img = styled.img`
  width: 100%;
  border-radius: 50%;
  margin-left: 12px;
  display: block;
  :hover {
    cursor: pointer;
  }
`
const TranslateContent = styled.div`
  display: none;
  @media screen and (min-width: 1200px) {
    display: flex;
    padding: 0 39px;
    align-items: center;
    justify-content: space-evenly;
  }
`
const TranslateContentMobile = styled.div`
  display: ${({ isMenuMobileOpen }) => (isMenuMobileOpen ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  @media screen and (min-width: 1200px) {
    display: none;
  }
`
const MenuDropdown = styled.div`
  position: relative;
  display: block;
  margin: 0 auto;
  &:hover ${Span} {
    color: ${({ theme }) => theme.colors.red};
  }
`
const SubMenu = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  visibility: hidden;
  padding: ${({ isMenuMobileOpen }) => (isMenuMobileOpen ? '10px 0 10px 15px' : '0')};
  height: 0px;
  width: 210px;
  font-size: 16px;
  overflow: hidden;
  background-color: transparent;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  @media screen and (min-width: 1200px) {
    position: absolute;
    justify-content: space-between;
    margin-top: 41px;
    padding: 14px 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: ${({ theme }) => theme.colors.red};
    ${MenuDropdown}:hover > & {
      visibility: visible;
      height: 213px;
      opacity: 1;
    }
  }
  &.open {
    visibility: visible;
    height: 213px;
    opacity: 1;
  }
`
export default Header
