import React from 'react'
import styled from 'styled-components'
import { styles } from '../../Theme'
import { Container, Row, Column, Title } from '../../components'
import bgImage from '../../images/home-banner.jpg'

const StyledBanner = styled.div`
  background: linear-gradient(180deg, rgba(70, 69, 85, 0.3) 18.39%, rgba(70, 69, 85, 0.3)),
  url('${bgImage}') no-repeat;
  background-size: cover;
  max-width: 100%;
  min-height: 475px;
  display: flex;
  align-items: center;
  @media (min-width: 768px) {
    min-height: 600px;
  }
`
const ContainerText = styled.div`
  max-width: 792px;
`

const ButtonContainer = styled.div`
  margin-top: 24px;

  @media (min-width: 768px) {
    margin-top: 40px;
  }
`

const Banner = ({ text, children }) => {
  return (
    <StyledBanner>
      <Container>
        <Row>
          <Column width={1} alignItems='center'>
            <ContainerText>
              <Title
                mobileFontSize='20px'
                mobileLineHeight='24px'
                fontSize='24px'
                lineHeight='29px'
                color={styles.colors.white}
              >
                {text}
              </Title>
            </ContainerText>
            <ButtonContainer>{children}</ButtonContainer>
          </Column>
        </Row>
      </Container>
    </StyledBanner>
  )
}

export default Banner
