import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const PRIMARY = 'primary'

const SECONDARY = 'secondary'

const GRAPHIC = 'graphic'

const DefaultButton = styled.button`
  border: 0;
  border-radius: 4px;
  outline: none;
  min-height: 40px;
  min-width: 282px;
  max-width: 100%;
  font-weight: bold;
  font-size: 14px;
  font-style: normal;
  font-family: Montserrat-Bold;
  transition: all 0.3s ease-in-out;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
`

const Button = props => {
  const { variant = PRIMARY, ...rest } = props
  if (variant === PRIMARY) {
    return <PrimaryButton {...rest} />
  }
  if (variant === SECONDARY) {
    return <SecondaryButton {...rest} />
  }
  if (variant === GRAPHIC) {
    return <GraphicButton {...rest} />
  }
}

const PrimaryButton = styled(DefaultButton)(
  ({ theme }) => `
      background-color: ${theme.colors.red};
      color: ${theme.colors.white};
      &:hover {
        background-color: ${theme.colors.darkRed};
      }
      &:disabled {
        background-color: ${theme.colors.lightGrey};
        color: ${theme.colors.white}
        &:hover {
          cursor: not-allowed;
        }
      }
  `
)

const SecondaryButton = styled(DefaultButton)(
  ({ theme }) => `
    border: 1px solid ${theme.colors.red};
    background-color: ${theme.colors.white};
    color: ${theme.colors.red};
    &:hover {
      background-color: ${theme.colors.red};
      color: ${theme.colors.white};
    }
    &:disabled {
      background-color: ${theme.colors.lightGrey};
      color: ${theme.colors.white};
      border: 0;
      &:hover {
        cursor: not-allowed;
      }
  `
)

const GraphicButton = styled.button(
  ({ theme, checked }) => `
      border: ${checked ? `1px solid ${theme.colors.red}` : `1px solid ${theme.colors.darkGrey30}`} ;
      background-color: ${checked ? `${theme.colors.red}` : `${theme.colors.white}`};
      color: ${checked ? `${theme.colors.white}` : `${theme.colors.lightGrey}`};
      border-radius: 4px;
      outline: none;
      min-height: 22px;
      min-width: 36px;
      max-width: 100%;
      font-weight: bold;
      font-size: 14px;
      font-style: normal;
      font-family: Montserrat;
      transition: all 0.3s ease-in-out;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      cursor: pointer;
      margin-right: 10px;
      &:hover {
        background-color: ${theme.colors.red};
        color: ${theme.colors.white};
        border: 1px solid ${theme.colors.red};
      }
      &:disabled {
        background-color: ${theme.colors.lightGrey};
        color: ${theme.colors.white};
        border: 0;
        &:hover {
          cursor: not-allowed;
        }
  `
)

Button.propTypes = {
  variant: PropTypes.string
}

export default Button
