import React from 'react'
import DefaultTypography from './DefaultTypography'
import styled from 'styled-components'

const Title = ({ mobileFontSize, mobileLineHeight, ...props }) => (
  <StyledTypography
    as='h1'
    fontFamily='Montserrat-Bold'
    fontSize='52px'
    lineHeight='63px'
    mobileFontSize={mobileFontSize}
    mobileLineHeight={mobileLineHeight}
    {...props}
  />
)

const StyledTypography = styled(DefaultTypography)`
  font-size: ${({ mobileFontSize }) => (mobileFontSize ? mobileFontSize : '32px')};
  line-height: ${({ mobileLineHeight }) => (mobileLineHeight ? mobileLineHeight : '39px')};
  @media only screen and (min-width: 480px) {
    font-size: ${({ mobileFontSize }) => (mobileFontSize ? mobileFontSize : '48px')};
    line-height: ${props => props.lineHeight};
  }
  @media only screen and (min-width: 768px) {
    font-size: ${props => props.fontSize};
    font-family: ${props => props.fontFamily};
    color: ${props => props.color};
    line-height: ${props => props.lineHeight};
  }
`

export default Title
