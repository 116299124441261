import React from 'react'
import DefaultTypography from './DefaultTypography'

const Text = props => (
  <DefaultTypography
    as='p'
    fontFamily='Montserrat'
    fontSize='16px'
    lineHeight='26px'
    {...props}
    dangerouslySetInnerHTML={props.dangerousHtml && { __html: props.dangerousHtml }}
  />
)

export default Text
