import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { TextInput, Legend } from '../'
import { styles } from '../../Theme'

const Input = ({ label, field, form, ...rest }) => {
  const { multiline, disabled, error } = rest
  const handleColor = () => {
    if (form.touched[field.name] && form.errors[field.name]) return styles.colors.darkRed
    if (disabled) return styles.colors.lightGrey

    return styles.colors.darkGrey
  }

  return (
    <Wrapper multiline={multiline} disabled={disabled} error={error}>
      {label && (
        <TextInput
          {...rest}
          color={handleColor()}
          error={form.touched[field.name] && form.errors[field.name]}
          htmlFor={field.name}
        >
          {label}
        </TextInput>
      )}
      {multiline ? (
        <StyledTextInput {...field} {...rest} error={form.touched[field.name] && form.errors[field.name]} />
      ) : (
        <StyledInput {...field} {...rest} error={form.touched[field.name] && form.errors[field.name]} />
      )}
      <Legend color={styles.colors.darkRed}>{form.touched[field.name] && form.errors[field.name]}</Legend>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${({ theme: { colors }, disabled, error, multiline }) => {
    if (multiline) {
      return ''
    }
    if (error || disabled) {
      return `
      &:focus-within {
        div {
          border: 1px solid ${error ? colors.darkRed : colors.lightGrey};
        }
        p:first-child {
          color: ${error ? colors.darkRed : colors.lightGrey20};
        }
      }
    `
    }
    return `
      &:focus-within {
        input {
          border: 1px solid ${colors.blue};
          box-shadow: 0px 2px 8px rgba(32, 160, 246, 0.25);
        }
      }
    `
  }}
`
const defaultStyles = css`
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  border: 1px solid ${({ theme }) => theme.colors.darkGrey};
  outline: none;
  flex: 1;
  width: 100%;
  height: 40px;
  background: transparent;
  padding: 7px 10px;
  margin: 10px 0 5px 0;
  color: ${({ theme }) => theme.colors.darkGrey};
  ::placeholder {
    color: ${({ theme }) => theme.colors.darkGrey30};
  }
  ${({ theme: { colors }, ...props }) => {
    if (props.disabled) {
      return `
        border: 1px solid ${colors.lightGrey};
        background: ${colors.lightGrey20};`
    }
    if (props.error) {
      return `border: 1px solid ${colors.darkRed};`
    }
  }}
`
const StyledInput = styled.input`
  ${defaultStyles};
  height: 40px;
`
const StyledTextInput = styled.textarea`
  ${defaultStyles};
  min-height: 233px;
  resize: none;
  ::-webkit-scrollbar {
    width: 8px;
    background: ${props => props.theme.colors.lightGrey20};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${props => props.theme.colors.darkGrey};
  }
  @media (min-width: 768px) {
    min-height: 223px;
  }
`

Input.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string
}

export default Input
