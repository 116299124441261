import React from 'react'
import styled from 'styled-components'
import { TitleSection, Text, Row, Column, Container } from '../'
import { styles } from '../../Theme'
import { useTranslation } from 'react-i18next'

const Section = ({ title, text, children, ...rest }) => {
  const { t } = useTranslation()
  return (
    <StyledContainer {...rest}>
      <Row justifyContent='center'>
        <Column width={[1, 3 / 4]}>
          <StyledTitle color={styles.colors.red}>{t(title)}</StyledTitle>
          {t(text, { returnObjects: true }).map(line => (
            <StyledText dangerousHtml={line} key={line} />
          ))}
          {children && <Row justifyContent='center'>{children}</Row>}
        </Column>
      </Row>
    </StyledContainer>
  )
}

const StyledContainer = styled(Container)`
  margin-top: 48px;
  margin-bottom: 48px;
  text-align: left;
  @media (min-width: 768px) {
    text-align: ${({ textAlign }) => textAlign || 'left'};
  }
`
const StyledTitle = styled(TitleSection)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
`
const StyledText = styled(Text)`
  text-align: left;
  margin-bottom: 24px;
`
export default Section
