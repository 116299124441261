import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Container, Table } from '../../components'

const HomeGraphicsSection = () => {
  const { t } = useTranslation()

  const [tablePricesError, setTablePricesError] = useState(false)
  const [ricePrice, setRicePrice] = useState([])
  const [soyPrice, setSoyPrice] = useState([])
  const [cornPrice, setCornPrice] = useState([])
  const [wheatPrice, setWheatPrice] = useState([])

  const indicators = [{ title: t('table:product') }, { title: t('table:price') }, { title: t('table:var') }]

  const getTableCotation = async () => {
    const requestDay = moment().subtract(1, 'day')
    if (requestDay.format('dddd') === 'Sunday') requestDay.subtract(1, 'day')
    if (requestDay.format('dddd') === 'Saturday') requestDay.subtract(1, 'day')
    const requestStartDay = requestDay.format('YYYY-MM-DD')
    try {
      const [riceRes, soyRes, cornRes, wheatRes] = await Promise.all([
        axios.get(
          `https://www.quandl.com/api/v3/datasets/CEPEA/RICE.json?api_key=D8BfK1-svZszJ5dedwpZ&start_date=${requestStartDay}`
        ),
        axios.get(
          `https://www.quandl.com/api/v3/datasets/CEPEA/SOYBEAN.json?api_key=D8BfK1-svZszJ5dedwpZ&start_date=${requestStartDay}`
        ),
        axios.get(
          `https://www.quandl.com/api/v3/datasets/CEPEA/CORN.json?api_key=D8BfK1-svZszJ5dedwpZ&start_date=${requestStartDay}`
        ),
        axios.get(
          `https://www.quandl.com/api/v3/datasets/CEPEA/WHEAT_R.json?api_key=D8BfK1-svZszJ5dedwpZ&start_date=${requestStartDay}`
        )
      ])

      if (
        !riceRes.data.dataset.data.length ||
        !soyRes.data.dataset.data.length ||
        !cornRes.data.dataset.data.length ||
        !wheatRes.data.dataset.data.length
      ) {
        const [riceResponse, soyResponse, cornResponse, wheatResponse] = await Promise.all([
          axios.get(
            `https://www.quandl.com/api/v3/datasets/CEPEA/RICE.json?api_key=D8BfK1-svZszJ5dedwpZ&start_date=${riceRes.data.dataset.newest_available_date}`
          ),
          axios.get(
            `https://www.quandl.com/api/v3/datasets/CEPEA/SOYBEAN.json?api_key=D8BfK1-svZszJ5dedwpZ&start_date=${soyRes.data.dataset.newest_available_date}`
          ),
          axios.get(
            `https://www.quandl.com/api/v3/datasets/CEPEA/CORN.json?api_key=D8BfK1-svZszJ5dedwpZ&start_date=${cornRes.data.dataset.newest_available_date}`
          ),
          axios.get(
            `https://www.quandl.com/api/v3/datasets/CEPEA/WHEAT_R.json?api_key=D8BfK1-svZszJ5dedwpZ&start_date=${wheatRes.data.dataset.newest_available_date}`
          )
        ])
        setRicePrice(riceResponse.data.dataset.data.length ? riceResponse.data.dataset.data[0] : [])
        setSoyPrice(soyResponse.data.dataset.data.length ? soyResponse.data.dataset.data[0] : [])
        setCornPrice(cornResponse.data.dataset.data.length ? cornResponse.data.dataset.data[0] : [])
        setWheatPrice(wheatResponse.data.dataset.data.length ? wheatResponse.data.dataset.data[0] : [])
        return
      }

      setRicePrice(riceRes.data.dataset.data[0])
      setSoyPrice(soyRes.data.dataset.data[0])
      setCornPrice(cornRes.data.dataset.data[0])
      setWheatPrice(wheatRes.data.dataset.data[0])
    } catch {
      setTablePricesError(true)
    }
  }

  const mountIndicatorsBody = _ => {
    const indicatorsBody = []
    if (ricePrice.length) indicatorsBody.push({ name: t('table:rice'), price: ricePrice[1], variation: ricePrice[2] })
    if (soyPrice.length) indicatorsBody.push({ name: t('table:soy'), price: soyPrice[1], variation: soyPrice[2] })
    if (cornPrice.length) indicatorsBody.push({ name: t('table:corn'), price: cornPrice[1], variation: cornPrice[2] })
    if (wheatPrice.length)
      indicatorsBody.push({ name: t('table:wheat'), price: wheatPrice[1], variation: wheatPrice[2] })

    return indicatorsBody
  }

  useEffect(() => {
    getTableCotation()
  }, [])

  return (
    <StyledContainer>
      <Table
        title={t('table:title2')}
        source={t('table:subtitle2')}
        header={indicators}
        body={mountIndicatorsBody()}
        error={tablePricesError}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export default HomeGraphicsSection
