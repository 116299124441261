import React, { useState } from 'react'
import styled from 'styled-components'

import { BrokerCard } from '..'
import leftArrow from '../../images/left-arrow.svg'
import rightArrow from '../../images/right-arrow.svg'

const BrokerSlider = ({ brokers }) => {
  const [visibleBrokerIndex, setVisibleBrokerIndex] = useState(0)

  const handlerNext = () => {
    if (visibleBrokerIndex === brokers.length - 1) {
      return setVisibleBrokerIndex(0)
    }
    return setVisibleBrokerIndex(visibleBrokerIndex + 1)
  }

  const handlerPrevious = () => {
    if (visibleBrokerIndex === 0) {
      return setVisibleBrokerIndex(brokers.length - 1)
    }
    return setVisibleBrokerIndex(visibleBrokerIndex - 1)
  }

  return (
    <SliderContainer>
      <StyledArrows src={leftArrow} onClick={handlerPrevious} />
      <BrokersContainer>
        <BrokerCard broker={brokers.find((broker, index) => index === visibleBrokerIndex)} />
      </BrokersContainer>
      <StyledArrows src={rightArrow} onClick={handlerNext} />
    </SliderContainer>
  )
}

const BrokersContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  @media (min-width: 768px) {
    margin-bottom: 32px;
  }
`

const StyledArrows = styled.img`
  padding: 15px;
  &:hover {
    cursor: pointer;
  }
`
const SliderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 55px;
  @media (min-width: 768px) {
    display: none;
  }
`

export default BrokerSlider
