import React from 'react'
import DefaultTypography from './DefaultTypography'
import { Link } from 'gatsby'
import styled from 'styled-components'

const TextLink = props => {
  const { color, to, children } = props
  return (
    <Link to={to}>
      <StyledTypography as='p' fontFamily='Montserrat' fontSize='16px' color={color} lineHeight='20px' {...props}>
        {children}
      </StyledTypography>
    </Link>
  )
}

export default TextLink

const StyledTypography = styled(DefaultTypography)`
  text-decoration-line: underline;
  color: ${props => props.color};
  line-height: 20px;
`
