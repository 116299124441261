import React from 'react'
import styled from 'styled-components'

import { Container, BrokerCard } from '../../components'

const BrokersCard = ({ brokers }) => (
  <BrokersContainer>
    {brokers.map(broker => (
      <BrokerCard broker={broker} key={broker.id} />
    ))}
  </BrokersContainer>
)

const BrokersContainer = styled(Container)`
  display: none;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 56px;
  justify-content: center;
  @media (min-width: 768px) {
    display: flex;
  }
`

export default BrokersCard
