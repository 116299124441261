import React from 'react'
import { GoogleMap, withScriptjs, withGoogleMap, Marker } from 'react-google-maps'
import styled from 'styled-components'

const Maps = () => (
  <GoogleMap defaultZoom={16} defaultCenter={{ lat: -31.745756, lng: -52.333037 }}>
    <Marker position={{ lat: -31.745756, lng: -52.333037 }} />
  </GoogleMap>
)

const WrapperMap = withScriptjs(withGoogleMap(Maps))

const MapContainer = () => {
  return (
    <div>
      <WrapperMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.MAPS_API_KEY}`}
        loadingElement={<MapElement />}
        containerElement={<MapElement />}
        mapElement={<MapElement />}
      />
    </div>
  )
}

export default MapContainer

const MapElement = styled.div`
  width: 100%;
  height: 300px;
  @media (min-width: 768px) {
    height: 400px;
  }
`
