import React from 'react'
import styled, { keyframes } from 'styled-components'
import loader from '../../images/loader.svg'

const Loader = () => <Spinner src={loader} />

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.img`
  animation: ${rotate} 1s linear infinite;
`

export default Loader
