import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Logo from '../Logo'
import { Text, Subtitle } from '../Typography'
import { styles } from '../../Theme'
import Container from '../Container'
import { Row, Column } from '../Grid'
import { useTranslation } from 'react-i18next'

import LogoImg from '../../images/logo.png'
import FacebookIcon from '../../images/facebook-icon.svg'
import InstagramIcon from '../../images/instagram-icon.svg'
import TwitterIcon from '../../images/twitter-icon.svg'

const Footer = () => {
  const { t } = useTranslation()

  return (
    <Section>
      <Container>
        <StyledRow flexWrap='wrap' width='100%' justifyContent='space-between' alignItems='flex-start'>
          <StyledColumn pr={2} pl={2}>
            <StyledLink to='/'>
              <Logo src={LogoImg} />
            </StyledLink>
            <StyledText color={styles.colors.lightGrey}> {t('footer:subtitle')} </StyledText>
            <ContainerIcons maxWidth={100}>
              <a href='https://www.facebook.com/arrozsulnegociosrurais' target='_blank'>
                <Icon src={FacebookIcon} />
              </a>
              <a href='https://instagram.com/corretora.arrozsul' target='_blank'>
                <Icon src={InstagramIcon} />
              </a>
              <a href='https://twitter.com/Arroz_Sul' target='_blank'>
                <Icon src={TwitterIcon} />
              </a>
            </ContainerIcons>
          </StyledColumn>
          <StyledColumn pr={2} pl={2}>
            <Subtitle>{t('footer:institutional')}</Subtitle>
            <StyledLink to='/'>
              <Text>{t('footer:home')}</Text>
            </StyledLink>
            <StyledLink to='/about'>
              <Text>{t('footer:about')}</Text>
            </StyledLink>
            <StyledLink to='/contact'>
              <Text>{t('footer:contact')}</Text>
            </StyledLink>
          </StyledColumn>
          <StyledColumn pr={2} pl={2}>
            <Subtitle>{t('footer:services')}</Subtitle>
            <StyledLink to='/services/real-estate'>
              <Text>{t('footer:realEstateAdvice')}</Text>
            </StyledLink>
            <StyledLink to='/services/rice-trading'>
              <Text>{t('footer:grainMarketing')}</Text>
            </StyledLink>
            <StyledLink to='/services/foreign-trade'>
              <Text>{t('footer:foreignTrade')}</Text>
            </StyledLink>
            <StyledLink to='/services/commodities'>
              <Text>{t('footer:commodityExchange')}</Text>
            </StyledLink>
            <StyledLink to='/services/drying-and-storing'>
              <Text>{t('footer:dryingAndStorage')}</Text>
            </StyledLink>
          </StyledColumn>
          <StyledColumn pr={2} pl={2}>
            <Subtitle>{t('footer:address')}</Subtitle>
            <Text>{t('footer:street')}</Text>
            <Text>{t('footer:city')}</Text>
            <Text>{t('footer:phones')}</Text>
          </StyledColumn>
        </StyledRow>
      </Container>
    </Section>
  )
}

export default Footer

const Section = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  border-top: 5px solid ${({ theme }) => theme.colors.red};
`
const StyledRow = styled(Row)`
  margin: 48px auto 32px auto;
  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`
const StyledColumn = styled(Column)`
  margin-bottom: 32px !important;
  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`

const ContainerIcons = styled.div`
  display: flex;
  width: 100px;
  justify-content: space-between;
  margin-top: 18px;
`
const Icon = styled.img``

const StyledText = styled(Text)`
  margin-bottom: 10px;
  text-align: center;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`
