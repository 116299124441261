import React from 'react'
import styled from 'styled-components'
import { styles } from '../../Theme'
import { Button, TitleSection } from '../../components'
import bgImage from '../../images/contact-banner.jpg'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'

const StyledBanner = styled.div`
  background: linear-gradient(180deg, rgba(70, 69, 85, 0.3) 18.39%, rgba(70, 69, 85, 0.3) 52.46%),
    url(${bgImage}) no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 304px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const StyledButton = styled(Button)`
  min-width: 180px;
  margin-top: 24px;
`

const ContactBanner = () => {
  const { t } = useTranslation()

  return (
    <StyledBanner bgImage>
      <TitleSection color={styles.colors.white}>{t('contactBanner:text')}</TitleSection>
      <Link to='/contact'>
        <StyledButton>{t('contactBanner:button-text')}</StyledButton>
      </Link>
    </StyledBanner>
  )
}

export default ContactBanner
