import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Header from '../Header'
import styled from 'styled-components'

import { SEO } from '..'
import { style } from 'styled-system'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <SEO />
      <Header />
      <Main>{children}</Main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout

const Main = styled.main`
  padding-top: 64px;
  @media screen and (min-width: 1200px) {
    padding-top: 98px;
  }
`
