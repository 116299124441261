import React from 'react'
import styled from 'styled-components'
import { Subtitle, Text } from '../../components'
import styles from '../../Theme/styles'
import avatarImg from '../../images/default-avatar.jpg'

const BrokerCard = ({ broker: { image, name, whatsapp } }) => (
  <AvatarContainer>
    <StyledImage src={image ? image : avatarImg} alt='avatar' />
    <div>
      <Subtitle color={styles.colors.red}>{name}</Subtitle>
      <Text color={styles.colors.lightGrey}>{whatsapp}</Text>
    </div>
  </AvatarContainer>
)

const AvatarContainer = styled.div`
  display: flex;
  width: 327px;
  align-items: center;
  @media (min-width: 768px) {
    margin-bottom: 29px;
  }
`

const StyledImage = styled.img`
  display: flex;
  border-radius: 50%;
  width: 78px;
  height: 78px;
  margin-right: 24px;
`

export default BrokerCard
