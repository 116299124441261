import React, { useState, useEffect } from 'react'
import { FacebookProvider, Page, Like } from 'react-facebook'
import ContainerSocialNetwork from './ContainerSocialNetwork'
import { styles } from '../../Theme'
import styled from 'styled-components'
const ContainerFacebook = () => {
  const [widthSize, setWidthSize] = useState(500 + 30)
  const getSize = () => {
    if (window.innerWidth < 360) {
      return 310
    } else if (window.innerWidth >= 360) {
      return 380
    } else if (window.innerWidth >= 480) {
      return 430
    } else if (window.innerWidth >= 768) {
      return 530
    } else if (window.innerWidth >= 832) {
      return 430
    } else if (window.innerWidth >= 1054) {
      return 530
    }
  }
  useEffect(_ => {
    const handleResize = _ => {
      setWidthSize(getSize())
    }
    window.addEventListener('resize', handleResize)
    return _ => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <FacebookProvider appId={process.env.FACEBOOK_APP_ID}>
      <ContainerSocialNetwork
        title='Facebook'
        color={styles.colors.blueFacebook}
        header={
          <ContainerButtonLike>
            <Like href='https://www.facebook.com/arrozsulnegociosrurais' colorScheme='dark' width={30} />
          </ContainerButtonLike>
        }
        section={
          <Page
            width={widthSize}
            href='https://www.facebook.com/arrozsulnegociosrurais'
            tabs='timeline'
            height='459px'
          />
        }
      />
    </FacebookProvider>
  )
}
export default ContainerFacebook
const ContainerButtonLike = styled.div`
  display: flex;
  width: 300px;
  overflow: hidden;
  @media (min-width: 360px) {
    width: auto;
  }
`
