import React from 'react'
import { Box } from 'reflexbox'
import styled from 'styled-components'

const Column = props => <StyledBox {...props} />

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0px 8px;
  @media (min-width: 768px) {
    padding: 0px 12px;
  }
`

export default Column
