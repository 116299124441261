import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import { TitleSection, Text, Row, Column, Container, Button, Loader } from '../../components'
import { styles } from '../../Theme'

const ConabReport = () => {
  const [latestReport, setLatestReport] = useState({ url: null, error: false, loading: true })
  const { t } = useTranslation()

  const getLatestReport = async () => {
    try {
      const { data } = await axios.get(
        'http://ec2-18-228-44-54.sa-east-1.compute.amazonaws.com:3000/v1/rice-report?last=true'
      )
      setLatestReport({ url: data.file_url, loading: false })
    } catch {
      setLatestReport({ error: true, loading: false })
    }
  }

  useEffect(() => {
    getLatestReport()
  }, [])

  if (latestReport.loading)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    )

  return (
    <Container>
      <OuterWrapper>
        <InnerWrapper>
          <StyledTitle color={styles.colors.red} fontSize='20px' lineHeight='24px'>
            {t('home:weekly-history')}
          </StyledTitle>
          <Subtitle color={styles.colors.lightGrey}>{t('home:weekly-history-subtitle')}</Subtitle>
        </InnerWrapper>
        <StyledContainer>
          <Row>
            <Column width={1} alignItems='center'>
              <StyledText>{t('home:weekly-history-text')}</StyledText>
              {latestReport.error || !latestReport.url ? (
                <Text>{t('home:weekly-history-button-error')}</Text>
              ) : (
                <a target='_blank' href={latestReport.url}>
                  <StyledButton>{t('home:weekly-history-button')} </StyledButton>
                </a>
              )}
            </Column>
          </Row>
        </StyledContainer>
      </OuterWrapper>
    </Container>
  )
}

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0px;
`

const OuterWrapper = styled.div`
  margin: 12px;
`

const InnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

const StyledContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
  padding: 32px 15px;
  text-align: left;
  background-color: ${styles.colors.white};
  box-shadow: 0px 2px 8px rgba(171, 169, 188, 0.2);
  border-radius: 4px;

  @media (min-width: 768px) {
    text-align: ${({ textAlign }) => textAlign || 'left'};
  }
`
const StyledTitle = styled(TitleSection)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`
const StyledText = styled(Text)`
  text-align: left;
  margin-bottom: 24px;
  width: 100%;

  @media (min-width: 992px) {
    width: 780px;
  }
`
const StyledButton = styled(Button)`
  min-width: 180px;

  @media (min-width: 768px) {
    min-width: 218px;
  }
`

const Subtitle = styled(Text)`
  display: flex;
  align-items: center;
  margin-left: 0;
  width: 100%;

  @media (min-width: 768px) {
    width: fit-content;
    margin-left: 24px;
  }
`

export default ConabReport
