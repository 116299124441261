import React from 'react'
import { TitleCard, Text } from '../Typography'
import { styles } from '../../Theme/styles'
import styled from 'styled-components'

const modifyTexts =
  'tr%3etd%2ctr%3etd%3espan' + '%7b' + 'font-size%3a14px!important%3b' + 'line-height%3a40px!important%3b' + '%7d'

const tableProperties = 'table' + '%7b' + 'width%3a450px!important%3b' + '%7d'

const htmlProperties = 'html' + '%7b' + 'height%3a100%25!important%3b' + '%7d'

const bodyProperties =
  'body' +
  '%7b' +
  'margin%3a0!important%3b' +
  'padding%3a0!important%3b' +
  'height%3A100%25%3Bdisplay%3Aflex%3Balign-items%3Acenter%3Bjustify-content%3Acenter%3B' +
  '%7d'

const commoditiesUrl =
  'https://sslcomrates.forexprostools.com/index.php?force_lang=12&pairs_ids=13916;8916;8918;8917' +
  '&header-text-color=%23000' +
  '%7d' +
  tableProperties +
  htmlProperties +
  bodyProperties +
  modifyTexts +
  'body%3ea%2cdiv.inlineblock%3ea%2ctr%3etd%3afirst-child' +
  '%7b' +
  'display%3anone!important' +
  '&header-bg=%23FFF' +
  '&curr-name-color=%23000' +
  '&curr-name-font-size=30px' +
  '&inner-text-color=%23000' +
  '&green-text-color=%232a8212' +
  '&green-background=transparent;' +
  '&red-text-color=%23d90000' +
  '&red-background=transparent;' +
  '&inner-border-color=%2300' +
  '&border-color=%23FFF' +
  '&bg1=%23FFF' +
  '&bg2=%23FFF' +
  '&open=hide' +
  '&month=hide' +
  '&high=hide' +
  '&low=hide' +
  '&change=hide' +
  '&last_update=hide'

const CommoditiesTable = ({ title, source }) => {
  return (
    <Card>
      <CardHeader>
        <TitleCard color={styles.colors.red}>{title}</TitleCard>
        <FontTitle color={styles.colors.lightGrey}>{source}</FontTitle>
      </CardHeader>
      <CardBody>
        <CardCover />
        <Body>
          <iframe style={{ height: '100%', width: '100%' }} src={commoditiesUrl} />
        </Body>
      </CardBody>
    </Card>
  )
}

const CardCover = styled.div`
  top: 0;
  width: 100%;
  background: white;
`

const Card = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  margin: 48px 12px 32px 12px;
`

const CardHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 0 14px 0;
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`
const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;
  position: relative;
`

const Body = styled.div`
  flex: 1;
  padding: 0 20px;
  min-height: 185px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(171, 169, 188, 0.2);
`

const FontTitle = styled(Text)`
  display: flex;
  align-items: center;
  margin-left: 0;
  @media screen and (min-width: 1200px) {
    margin-left: 24px;
  }
`

export default CommoditiesTable
