import moment from 'moment'

const cityIdPelotas = process.env.CITY_ID_PELOTAS
const cityIdArroioGrande = process.env.CITY_ID_ARROIO_GRANDE
const cityIdSantaVitoriaDoPalmar = process.env.CITY_ID_SANTA_VITORIA_DO_PALMAR
const cityIdJaguarao = process.env.CITY_ID_JAGUARAO

export const weekDaySecondary = date => moment(date).format('ddd')

export const getMinMax = list => {
  const min = Math.min.apply(Math, list.map(day => day.main.temp_min))
  const max = Math.max.apply(Math, list.map(day => day.main.temp_max))
  return { temp_max: max, temp_min: min }
}

export const fetchIdCity = city => {
  switch (city) {
    case 1:
      return cityIdArroioGrande
    case 2:
      return cityIdPelotas
    case 3:
      return cityIdSantaVitoriaDoPalmar
    case 4:
      return cityIdJaguarao
    default:
      return cityIdPelotas
  }
}

export const cities = [
  {
    name: 'Pelotas',
    id: process.env.CITY_ID_PELOTAS
  },
  {
    name: 'Santa Vitória do Palmar',
    id: process.env.CITY_ID_SANTA_VITORIA_DO_PALMAR
  },
  {
    name: 'Arroio Grande',
    id: process.env.CITY_ID_ARROIO_GRANDE
  },
  {
    name: 'Jaguarão',
    id: process.env.CITY_ID_JAGUARAO
  }
]
