import React from 'react'
import styled from 'styled-components'

const Row = props => <StyledRow {...props} />

const StyledRow = styled.div(
  ({ width, flex, flexWrap = false, alignItems, justifyContent, ...props }) => `
  display: flex;
  ${width ? `width: ${width};` : ''}
  ${flex ? `flex: ${flex};` : ''}
  ${flexWrap ? `flex-wrap: ${flexWrap};` : ''}
  ${alignItems ? `align-items: ${alignItems};` : ''}
  ${justifyContent ? `justify-content: ${justifyContent};` : ''}
  margin: -0px -8px;
  @media (min-width: 768px) {
    margin: -0px -12px;
  }
`
)
export default Row
