import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { TitleCard, Text, Row, Column } from '../'
import { Legend } from '../Typography'
import { getMinMax, weekDaySecondary, cities } from './helpers'

import { styles } from '../../Theme'
import Sun from '../../images/sun.svg'
import Cloudy from '../../images/cloudy.svg'
import Cloudy2 from '../../images/cloudy2.svg'
import Snowy from '../../images/snowy.svg'
import Rain from '../../images/rain.svg'
import Arrow from '../../images/arrow.svg'
import Atmosphere from '../../images/atmosphere.svg'
import Cold from '../../images/cold.svg'

const Weather = () => {
  const { t } = useTranslation()
  const [citiesForecast, setCitiesForecast] = useState(null)
  const [selectedCity, setSelectedCity] = useState(0)

  const apiKey = process.env.OPEN_WEATHER_APP_ID
  const weekDay = moment().format('ddd')

  const dataWeather = weather => {
    switch (weather.id) {
      case 200:
        return typeWeather[0]
      case 201:
        return typeWeather[1]
      case 202:
        return typeWeather[2]
      case 210:
        return typeWeather[3]
      case 211:
        return typeWeather[4]
      case 212:
        return typeWeather[5]
      case 221:
        return typeWeather[6]
      case 230:
        return typeWeather[7]
      case 231:
        return typeWeather[8]
      case 232:
        return typeWeather[5]

      case 300:
        return typeWeather[10]
      case 301:
        return typeWeather[11]
      case 302:
        return typeWeather[10]
      case 310:
        return typeWeather[13]
      case 311:
        return typeWeather[14]
      case 312:
        return typeWeather[15]
      case 313:
        return typeWeather[14]
      case 314:
        return typeWeather[13]
      case 321:
        return typeWeather[11]

      case 500:
        return typeWeather[16]
      case 501:
        return typeWeather[17]
      case 502:
        return typeWeather[18]
      case 503:
        return typeWeather[19]
      case 504:
        return typeWeather[20]
      case 511:
        return typeWeather[21]
      case 520:
        return typeWeather[14]
      case 521:
        return typeWeather[14]
      case 522:
        return typeWeather[22]
      case 531:
        return typeWeather[23]

      case 600:
        return typeWeather[28]
      case 601:
        return typeWeather[29]
      case 602:
        return typeWeather[30]
      case 611:
        return typeWeather[31]
      case 612:
        return typeWeather[32]
      case 613:
        return typeWeather[33]
      case 615:
        return typeWeather[34]
      case 616:
        return typeWeather[35]
      case 620:
        return typeWeather[36]
      case 621:
        return typeWeather[37]
      case 622:
        return typeWeather[38]

      case 700:
        return typeWeather[39]
      case 701:
        return typeWeather[40]
      case 711:
        return typeWeather[41]
      case 721:
        return typeWeather[42]
      case 731:
        return typeWeather[43]
      case 741:
        return typeWeather[44]
      case 751:
        return typeWeather[45]
      case 761:
        return typeWeather[43]
      case 762:
        return typeWeather[46]
      case 771:
        return typeWeather[47]
      case 781:
        return typeWeather[48]

      case 800:
        return typeWeather[24]
      case 801:
        return typeWeather[25]
      case 802:
        return typeWeather[26]
      case 803:
        return typeWeather[27]
      case 804:
        return typeWeather[27]
      default:
        return typeWeather[24]
    }
  }

  const typeWeather = [
    { type: t('weather:LightRainThunderstorm'), icon: Rain },
    { type: t('weather:RainThunderstorm'), icon: Rain },
    { type: t('weather:HeavyRainThunderstorm'), icon: Rain },
    { type: t('weather:LightThunderstorm'), icon: Rain },
    { type: t('weather:Thunderstorm'), icon: Rain },
    { type: t('weather:HeavyThunderstorm'), icon: Rain },
    { type: t('weather:IrregularStorm'), icon: Rain },
    { type: t('weather:ThunderstormWithLightDrizzle'), icon: Rain },
    { type: t('weather:ThunderstormWithDrizzle'), icon: Rain },
    { type: t('weather:HeavyThunderstorm'), icon: Rain },

    { type: t('weather:HeavyDrizzle'), icon: Snowy },
    { type: t('weather:Drizzle'), icon: Snowy },
    { type: t('weather:HeavyDrizzle'), icon: Snowy },
    { type: t('weather:HeavyRain'), icon: Snowy },
    { type: t('weather:Rain'), icon: Snowy },
    { type: t('weather:HeavyRain'), icon: Snowy },

    { type: t('weather:LightRain'), icon: Snowy },
    { type: t('weather:LightRain'), icon: Snowy },
    { type: t('weather:VeryHeavyRain'), icon: Snowy },
    { type: t('weather:VeryHeavyRain'), icon: Snowy },
    { type: t('weather:ExtremeRain'), icon: Snowy },
    { type: t('weather:FreezingRain'), icon: Snowy },
    { type: t('weather:HeavyRain'), icon: Snowy },
    { type: t('weather:IrregularRain'), icon: Snowy },

    { type: t('weather:ClearSky'), icon: Sun },

    { type: t('weather:SunWithFewClouds'), icon: Cloudy },

    { type: t('weather:ScatteredClouds'), icon: Cloudy2 },
    { type: t('weather:Cloudy'), icon: Cloudy2 },

    { type: t('weather:LightSnow'), icon: Cold },
    { type: t('weather:Snow'), icon: Cold },
    { type: t('weather:HeavySnow'), icon: Cold },
    { type: t('weather:LightSnow'), icon: Cold },
    { type: t('weather:Sleet'), icon: Cold },
    { type: t('weather:LightShowerSleet'), icon: Cold },
    { type: t('weather:ShowerSleet'), icon: Cold },
    { type: t('weather:LightRainAndSnow'), icon: Cold },
    { type: t('weather:RainAndSnow'), icon: Cold },
    { type: t('weather:LightShowerSnow'), icon: Cold },
    { type: t('weather:ShowerSnow'), icon: Cold },
    { type: t('weather:HeavyShowerSnow'), icon: Cold },

    { type: t('weather:Mist'), icon: Atmosphere },
    { type: t('weather:Smoke'), icon: Atmosphere },
    { type: t('weather:Haze'), icon: Atmosphere },
    { type: t('weather:Dust'), icon: Atmosphere },
    { type: t('weather:Fog'), icon: Atmosphere },
    { type: t('weather:Sand'), icon: Atmosphere },
    { type: t('weather:Ash'), icon: Atmosphere },
    { type: t('weather:Squall'), icon: Atmosphere },
    { type: t('weather:Tornado'), icon: Atmosphere }
  ]

  const daysOfTheWeek = day => {
    switch (day) {
      case 'Sun':
        return t('weather:sunday')
      case 'Mon':
        return t('weather:monday')
      case 'Tue':
        return t('weather:tuesday')
      case 'Wed':
        return t('weather:wednesday')
      case 'Thu':
        return t('weather:thursday')
      case 'Fri':
        return t('weather:friday')
      case 'Sat':
        return t('weather:saturday')
    }
  }

  const formatAllDays = allDays => {
    const nextDay1 = allDays.data.list.filter(
      day =>
        moment(day.dt_txt).format('dd') ===
        moment()
          .add(1, 'd')
          .format('dd')
    )
    const nextDay2 = allDays.data.list.filter(
      day =>
        moment(day.dt_txt).format('dd') ===
        moment()
          .add(2, 'd')
          .format('dd')
    )
    const nextDay3 = allDays.data.list.filter(
      day =>
        moment(day.dt_txt).format('dd') ===
        moment()
          .add(3, 'd')
          .format('dd')
    )
    const nextDay4 = allDays.data.list.filter(
      day =>
        moment(day.dt_txt).format('dd') ===
        moment()
          .add(4, 'd')
          .format('dd')
    )
    const temperatureDay1 = getMinMax(nextDay1)
    const temperatureDay2 = getMinMax(nextDay2)
    const temperatureDay3 = getMinMax(nextDay3)
    const temperatureDay4 = getMinMax(nextDay4)

    return [
      { data: nextDay1[0], temp: temperatureDay1 },
      { data: nextDay2[0], temp: temperatureDay2 },
      { data: nextDay3[0], temp: temperatureDay3 },
      { data: nextDay4[0], temp: temperatureDay4 }
    ]
  }

  const handleCityAfter = () => {
    if (selectedCity < 3) setSelectedCity(selectedCity + 1)
    if (selectedCity === 3) setSelectedCity(0)
  }

  const handleCityBefore = () => {
    if (selectedCity > 0) setSelectedCity(selectedCity - 1)
    if (selectedCity === 0) setSelectedCity(3)
  }

  const getForecast = async _ => {
    const promises = cities.reduce(
      (promises, city) => [
        ...promises,
        axios.get(`https://api.openweathermap.org/data/2.5/weather?id=${city.id}&appid=${apiKey}&units=metric`),
        axios.get(`https://api.openweathermap.org/data/2.5/forecast?id=${city.id}&appid=${apiKey}&units=metric`)
      ],
      []
    )

    const forecastRequests = await Promise.all(promises)
    const forecastCities = cities.map((city, index) => ({
      ...city,
      weather: forecastRequests[index * 2].data,
      forecast: formatAllDays(forecastRequests[index * 2 + 1])
    }))

    setCitiesForecast(forecastCities)
  }

  useEffect(_ => {
    getForecast()
  }, [])

  if (!citiesForecast) return <ContainerSectionWeather></ContainerSectionWeather>

  return (
    <ContainerSectionWeather>
      <ContainerTitle>
        <StyledTitle color={styles.colors.red}>{t('weather:title')}</StyledTitle>
        <Text color={styles.colors.lightGrey}>{t('weather:subtitle')}</Text>
      </ContainerTitle>
      <Card>
        <ColumnCard>
          <ContainerSlider>
            <ArrowButton onClick={handleCityBefore}>
              <ArrowLeft src={Arrow} />
            </ArrowButton>
            <Text color={styles.colors.darkGrey}>{citiesForecast[selectedCity].name}</Text>
            <ArrowButton onClick={handleCityAfter}>
              <ArrowRight src={Arrow} />
            </ArrowButton>
          </ContainerSlider>
          <ContainerData flexWrap='wrap'>
            <ColumnCardPrimary width={[1, 1, 1 / 2]}>
              <RowCard>
                <Icon src={dataWeather(citiesForecast[selectedCity].weather.weather[0]).icon} />
                <Column>
                  <RowCard>
                    <StyledTitleCard color={styles.colors.darkGrey}>
                      {citiesForecast[selectedCity].weather.main.temp_max.toFixed(0)}°
                    </StyledTitleCard>
                  </RowCard>
                  <RowCard>
                    <Text color={styles.colors.darkGrey}>{daysOfTheWeek(weekDay)}</Text>
                  </RowCard>
                  <RowCard>
                    <Legend color={styles.colors.lightGrey}>
                      {dataWeather(citiesForecast[selectedCity].weather.weather[0]).type}
                    </Legend>
                  </RowCard>
                </Column>
              </RowCard>
            </ColumnCardPrimary>
            <ColumnCardSecondary width={[1, 1, 1 / 2]}>
              <RowIcons>
                {citiesForecast[selectedCity].forecast.map(dayForecast => {
                  return (
                    <ColumnIcons key={dayForecast.data.dt}>
                      <Legend>{daysOfTheWeek(weekDaySecondary(dayForecast.data.dt_txt)).substring(0, 3)}</Legend>
                      <IconSecondary src={dataWeather(dayForecast.data.weather[0]).icon} />
                      <Row>
                        <StyledLegend color={styles.colors.darkGrey}>
                          {dayForecast.temp.temp_max.toFixed(0)}°
                        </StyledLegend>
                        <Legend color={styles.colors.lightGrey}>{dayForecast.temp.temp_min.toFixed(0)}°</Legend>
                      </Row>
                    </ColumnIcons>
                  )
                })}
              </RowIcons>
            </ColumnCardSecondary>
          </ContainerData>
        </ColumnCard>
      </Card>
    </ContainerSectionWeather>
  )
}

export default Weather

const ContainerSectionWeather = styled(Column)`
  width: 100%;
`
const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`
const ContainerSlider = styled(Row)`
  width: 80%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    width: 40%;
    margin-bottom: 49px;
  }
`
const ContainerData = styled(Row)`
  width: 100%;
  justify-content: center;
  align-items: center;
`

const ColumnCard = styled(Column)`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 16px 21px 23px 21px;
  @media (min-width: 880px) {
    padding: 16px 150px 57px 150px;
  }
`
const ColumnCardPrimary = styled(ColumnCard)`
  padding: 0;
  @media (min-width: 880px) {
    align-items: flex-start;
    padding: 0;
  }
`
const ColumnCardSecondary = styled(ColumnCard)`
  padding: 0;
  @media (min-width: 880px) {
    align-items: flex-end;
    padding: 0;
  }
`
const RowCard = styled(Row)`
  justify-content: space-between;
  align-items: center;
`
const RowIcons = styled(Row)`
  justify-content: space-between;
`
const ColumnIcons = styled(Column)`
  justify-content: space-between;
  align-items: center;
`
const StyledTitleCard = styled(TitleCard)`
  margin-right: 10px;
`
const StyledTitle = styled(TitleCard)`
  margin-right: 24px;
`
const Card = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  margin: 0 0 48px 0;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 8px rgba(171, 169, 188, 0.2);
  border-radius: 4px;
`
const Icon = styled.img`
  width: 88px;
  height: 92px;
  margin-right: 27px;
  margin-bottom: 45px;
  @media (min-width: 880px) {
    margin-bottom: 0;
  }
`
const IconSecondary = styled.img`
  width: 48px;
  height: 48px;
  margin: 10px 0;
`
const ArrowRight = styled.img`
  cursor: pointer;
`
const ArrowLeft = styled(ArrowRight)`
  transform: rotate(180deg);
`
const ArrowButton = styled.button`
  border: none;
  background: transparent;
`
const StyledLegend = styled(Legend)`
  margin-right: 3px;
`
