import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Brokers = () => {
  const { t } = useTranslation()

  const brokers = [
    {
      name: 'Daniela Prietto',
      phone: '(53) 98403-4908',
      email: 'arrozsul@arrozsul.com.br',
      work_area: 'Administrativo'
    },
    {
      name: 'Fernando Vianna',
      phone: '(53) 99996-7022',
      email: 'arrozsul@arrozsul.com.br',
      work_area: 'Arroz'
    },
    {
      name: 'Guilherme Melo',
      phone: '(53) 98119-8291',
      email: 'comercial@arrozsul.com.br',
      work_area: 'Arroz e Soja'
    },
    {
      name: 'Jorge Freitas',
      phone: '(53) 98114-8544',
      email: 'arrozsul@arrozsul.com.br',
      work_area: 'Arroz'
    },
    {
      name: 'Michele Peres',
      phone: '(53) 98116-0533',
      email: 'michele@arrozsul.com.br',
      work_area: 'Arroz e Soja'
    },
    {
      name: 'Pablo Cardozo',
      phone: '(53) 99139-2178',
      email: 'arrozsul@arrozsul.com.br',
      work_area: 'Administrativo'
    },
    {
      name: 'Rafael Freitas',
      phone: '(53) 98114-8542',
      email: 'rafael@arrozsul.com.br',
      work_area: 'Arroz'
    },
    {
      name: 'Suelen Novack',
      phone: '(53) 98442-5942',
      email: 'financeiro@arrozsul.com.br',
      work_area: 'Financeiro'
    }
  ]

  return (
    <TableContainer>
      <TableContent>
        <Head>
          <HeaderItem>{t('brokers:header:broker')}</HeaderItem>
          <HeaderItem>{t('brokers:header:email')}</HeaderItem>
          <HeaderItem>{t('brokers:header:work_area')}</HeaderItem>
          <HeaderItem>{t('brokers:header:phone')}</HeaderItem>
        </Head>
        <Body>
          {brokers.map(broker => (
            <Item>
              <Data>{broker.name}</Data>
              <Data>{broker.email}</Data>
              <Data>{broker.work_area}</Data>
              <Data>{broker.phone}</Data>
            </Item>
          ))}
        </Body>
      </TableContent>
    </TableContainer>
  )
}

const TableContainer = styled.div`
  width: calc(100vw - 60px);
  overflow-x: auto;
  display: flex;
  justify-content: flex-start;
  @media (min-width: 1200px) {
    width: calc(100vw - 80px);
    justify-content: center;
    max-width: 1116px;
  }
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 2px 8px rgba(171, 169, 188, 0.2);
  -moz-box-shadow: 0px 2px 8px rgba(171, 169, 188, 0.2);
  box-shadow: 0px 2px 8px rgba(171, 169, 188, 0.2);
`

const TableContent = styled.table`
  /* max-width: 100%; */
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 4px;
  @media (min-width: 1200px) {
    width: 100%;
  }
`

const Head = styled.thead`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  box-shadow: inset 0px -1px 0px rgba(70, 69, 85, 0.3);
  tr {
    box-shadow: none;
  }
`

const HeaderItem = styled.th`
  width: 200px;
  font-family: 'Montserrat-Bold';
  font-size: 12px;
  @media (min-width: 360px) {
    font-size: 14px;
  }
  display: flex;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.darkGrey};
  text-align: ${({ first }) => (first ? 'left' : 'right')};
  line-height: 20px;
  padding: 14px 24px;
`

const Body = styled.tbody`
  padding: 11px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const Item = styled.tr`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px rgba(70, 69, 85, 0.3);
  &:last-of-type {
    box-shadow: none;
  }
  @media (min-width: 1200px) {
    width: 100%;
  }
`
const Data = styled.td`
  font-size: 12px;
  width: 200px;
  @media (min-width: 360px) {
    font-size: 14px;
  }

  display: flex;
  justify-content: flex-start;
  text-align: left;
  font-weight: 500;
  line-height: 26px;
  padding: 10px 24px;
  color: ${({ theme }) => theme.colors.darkGrey};
  text-align: ${({ first }) => (first ? 'left' : 'right')};
`
export default Brokers
