import React from 'react'
import styled from 'styled-components'
import { Line } from 'react-chartjs-2'
import styles from '../../Theme/styles'

const Graphic = ({ values, label, yAxisSize = 1, money = 'R$', xMaxTicksLimit = 6 }) => {
  const chartData = {
    labels: label,
    datasets: [
      {
        fill: true,
        lineTension: 0,
        backgroundColor: 'rgba(218, 36, 28, 0.4)',
        borderColor: styles.colors.red,
        pointBorderColor: styles.colors.red,
        pointBackgroundColor: styles.colors.red,
        pointRadius: 0,
        pointBorderWidth: 0,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 3,
        pointHoverBackgroundColor: styles.colors.red,
        pointHoverBorderColor: styles.colors.red,
        data: values
      }
    ]
  }
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    hover: {
      mode: 'nearest',
      intersect: false
    },
    tooltips: {
      mode: 'nearest',
      intersect: false,
      backgroundColor: styles.colors.darkGrey,
      bodySpacing: 8,
      cornerRadius: 4,
      displayColors: false,
      borderColor: styles.colors.darkGrey,
      callbacks: {
        label: tooltipItems => `${money} ${tooltipItems.yLabel}`
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: false,
            stepSize: yAxisSize,
            maxTicksLimit: 5,
            minRotation: 0,
            maxRotation: 0,
            callback: value => `${money} ${value.toFixed(2)}`
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            maxTicksLimit: xMaxTicksLimit,
            maxRotation: 0,
            beginAtZero: false,
            padding: 10
          }
        }
      ]
    }
  }
  return (
    <div>
      <Line data={chartData} width={500} height={159} legend={false} options={chartOptions} />
    </div>
  )
}

export default Graphic
