import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 290px;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 360px) {
    max-width: 320px;
  }
  @media (min-width: 480px) {
    max-width: 420px;
  }
  @media (min-width: 768px) {
    max-width: 708px;
  }
  @media (min-width: 992px) {
    max-width: 932px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`
export default Container
